<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="officialWebsite" @click="jumpOfficialWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="content-box">
      <div class="title"></div>
      <div class="subtitle"></div>
      <div class="person1"></div>
      <div class="person2"></div>
      <div class="footer-bg">
        <div class="downloadBox">
          <img class="qrcodeBox" :src="qrImg" alt="" />
        </div>
        <div class="left-bottom-bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {};
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/qiyoushequ");
    },
    toPotato() {
      window.open("https://chptdl.org/qiyoushequ");
    },
    jumpOfficialWeb() {
      window.open("https://emcmqw.com");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    top: 65px;
    left: 62px;
    height: 121px;
    width: 121px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    top: 59px;
    right: 112.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .officialWebsite {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/officialWebsite.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .logoText {
    height: 319px;
    width: 821px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 222px;
    top: 263px;
  }
  .content-box {
    height: 908px;
    width: 1774px;
    position: absolute;
    bottom: 1vh;
    left: 50%;
    transform: translateX(-50%);
  }
  .title {
    width: 779px;
    height: 395px;
    position: absolute;
    top: 90px;
    left: 63px;
    background: url("./../../../assets/images/pc/title.webp") no-repeat;
    background-size: 100% 100%;
  }
  .subtitle {
    width: 689px;
    height: 112px;
    position: absolute;
    top: 440px;
    left: 110px;
    background: url("./../../../assets/images/pc/subtitle.png") no-repeat;
    background-size: 100% 100%;
  }
  .person1 {
    width: 481px;
    height: 1080px;
    position: absolute;
    bottom: 38px;
    left: 884px;
    background: url("./../../../assets/images/pc/person1.webp") no-repeat;
    background-size: 100% 100%;
    z-index: 90;
  }
  .person2 {
    width: 680px;
    height: 908px;
    position: absolute;
    bottom: 49px;
    left: 1122px;
    background: url("./../../../assets/images/pc/person2.webp") no-repeat;
    background-size: 100% 100%;
    z-index: 80;
  }
  .downloadBox {
    position: absolute;
    left: 40px;
    top: 46px;
    z-index: 101;
    .qrcodeBox {
      height: 160px;
      width: 160px;
      background-color: white;
    }
  }
  .left-bottom-bg {
    width: 177px;
    height: 280px;
    position: absolute;
    left: 31px;
    bottom: 22px;
    z-index: 100;
    background: url("./../../../assets/images/pc/left-bottom-bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .footer-bg {
    width: 1774px;
    height: 339px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 38px;
    z-index: 99;
    background: url("./../../../assets/images/pc/footer-bg.webp") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
